import Hero from '../../components/hero/Hero';
import './Home.css';

export default function Home() {

    return (
        <main>
            <Hero/>
        </main>
    )
}